@tailwind base;

@layer base {
	body {
		font-size: 16px;
		line-height: 1.75em;
		@apply antialiased text-slate-800 bg-white;
	}

	a {
		@apply text-sky-700 hover:text-sky-900 no-underline hover:no-underline;
		@apply focus-visible:outline focus-visible:outline-blue-400 focus-visible:outline-1 rounded-lg;
	}

	h1 {
		font-size: 2.25rem;
		@apply font-bold;
	}

	h2 {
		font-size: 1.5rem;
		@apply font-semibold;
	}

	h3 {
		font-size: 1.25rem;
		@apply font-medium;
	}

	h4 {
		font-size: 1rem;
		font-weight: 600;
	}

	code {
		color: #dddddd;
	}

	pre {
		background-color: #1a1b26;
		@apply rounded p-4 text-wrap;
	}
}

@tailwind components;
@tailwind utilities;

.logo {
	@apply no-underline hover:no-underline font-bold text-slate-900;
}

.logo:hover {
	@apply text-sky-600 underline;
}

.logo .wx {
	@apply font-raleway text-sky-600;
}

.logo:hover .wx {
	@apply text-slate-900;
}

.logo .api {
	@apply text-slate-600;
}

.logo:hover .api {
	@apply underline text-slate-600;
}

.foot-links {
	@apply text-sm pl-0;
}

.forms-label {
	@apply inline-block mb-2 font-medium;
}

.forms-field {
	@apply block w-full p-2 text-slate-900 border border-slate-300 rounded-lg bg-white;
	@apply focus-visible:border-blue-400 focus-visible:outline focus-visible:outline-blue-400;
	@apply focus-visible:outline-offset-0 focus:ring-blue-400 focus-visible:ring-blue-400 focus-visible:outline-0;
}

.forms-button,
.code-button {
	@apply text-center py-2 px-5 rounded-lg no-underline hover:no-underline font-medium;
	@apply text-slate-100 hover:text-slate-100 cursor-pointer transition hover:opacity-75;
	@apply focus-visible:outline focus-visible:outline-blue-400 focus-visible:outline-offset-2;
	@apply focus:ring-blue-400 focus-visible:ring-blue-400 focus-visible:outline-1;
}

.search-form {
	@apply block;
	@apply w-full;
	@apply p-4;
	@apply pl-10;
	@apply text-slate-900;
	@apply border;
	@apply border-slate-300;
	@apply rounded-lg;
	@apply bg-white;
	@apply focus:ring-slate-400;
	@apply focus:border-slate-400;
}

input.error {
	@apply border-red-500;
}

.pre-wrap {
	background-color: #1a1b26;
	@apply rounded p-4;
}

.wspan span {
	@apply text-sky-700 font-mono font-semibold;
}

[x-cloak] {
	display: none !important;
}

.custom .checkwx-item {
	font-size: 20px;
	color: blue;
}

.custom .checkwx-link {
	font-weight: bold;
	color: red;
	text-decoration: underline;
}
